.thinking-container {
    display: flex;
    justify-content: left;
    align-items: center;
    height: 100vh;
    /*background-color: #000;*/
}

.thinking-card {
    background: linear-gradient(135deg, #003366, #0066cc);
    color: white !important;
    padding: 16px;
    border-radius: 12px;
    border: 2px solid #1097da;
    /*box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.5);*/
    /*width: 300px;*/
    display: flex;
    align-items: center;
}

.thinking-text {
    font-size: 1.1rem !important;
    font-weight: bold;
}

.thinking-subtext {
    font-size: 0.9rem;
    opacity: 0.8;
}

.me-2 {
    margin-right: 8px;
}

.text-primary svg {
    color: #66ccff;
}
