.MuiDrawer-paper {
    justify-content: space-between !important;
    flex-direction: row;
}

@media all and (max-width: 600px) {
    .MuiDrawer-paper {
        flex-direction: column-reverse !important;
        justify-content: flex-end !important;
    }
}


/* Contenedor con scroll personalizado */
.scroll-container {
    width: 100%;
    overflow-y: auto;
    /*scrollbar-width: thin; !* Para Firefox *!*/
    /*scrollbar-color: rgba(255, 255, 255, 0.9) transparent;*/
    position: relative;
}

/* Estilos para WebKit (Chrome, Edge, Safari) */
.scroll-container::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 6px;
    transition: opacity 0.3s ease-in-out !important;
    opacity: 0 !important;
}

.content-scroll::-webkit-scrollbar:vertical {
    width:8px;
}

.content-scroll::-webkit-scrollbar-button:increment,.content-scroll::-webkit-scrollbar-button {
    display: none;
}

.content-scroll::-webkit-scrollbar:horizontal {
    height: 8px;
}

.scroll-container::-webkit-scrollbar-thumb {
    background: rgba(255, 255, 255, 0.5);
    border-radius: 10px;
    border: 2px solid #f1f2f3;
    cursor: pointer !important;
}

.scroll-container::-webkit-scrollbar-track {
    /*background: transparent;*/
    border-radius: 10px;
    background-color: rgba(255, 255, 255, 0.58);
}                                                                                         

/* Mostrar la barra de desplazamiento solo cuando se hace scroll */
.scroll-container:hover::-webkit-scrollbar {
    opacity: 1;
}

.scroll-container::-webkit-scrollbar-thumb:hover {
    background-color: rgba(68, 67, 67, 0.76);
}
